<template>
    <b-form-group class="disabled m-0">
        <b-form-input type="text" style="display: none"></b-form-input>
        <div class="d-flex justify-content-between align-items-center" v-show="timerEnabled">
            <label class="col-form-label">{{ $t('verification_code') }}</label>
            <div class="text-muted" v-if="timer>-1">
                <i class="ri-time-line top-plus-2 mr-1"></i>{{ timer }} sn
            </div>
        </div>
        <div v-show="timerEnabled">
            <otp-input
                class="sms-input"
                ref="otpInput"
                inputClasses="form-control text-center font-size-1"
                :numInputs="6"
                separator=""
                :shouldAutoFocus="true"
                @on-complete="otpHandleOnComplete($event)"
                @on-change="otpHandleOnChange($event)"
            />
        </div>
        <div style="" v-show="!timerEnabled">
            <div class="d-flex last-child-mr-0">
                <input min="0" max="9" maxlength="1" pattern="[0-9]" v-check-min-max-value type="tel" class="form-control text-center font-size-1 col mr-4 mr-sm-5" v-for="i in 6" :key="i" disabled>
            </div>
        </div>
    </b-form-group>
</template>

<script>
    import OtpInput from '@bachdgvn/vue-otp-input';

    export default {
        components: {
            OtpInput
        },
        props: {
          clearInput:{
            type:Boolean,
            default:false
          },
            value: {
                default: null
            },
            timerFrom: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                timerEnabled: false,
                timer: null
            }
        },
        watch: {
           clearInput(newValue){
             if (newValue){
               this.$refs.otpInput.clearInput()

             }

           },
            timerFrom: function(val) {

                if (val > 0) {
                  clearInterval(this.$options.interval);
                  this.timer = this.timerFrom;
                    this.timerEnabled = true;
                }
            },
            timer: function(val) {
                if (val != null) {
                    if (val > 0) {
                        this.$options.interval = setTimeout(() => {
                            this.timer--;
                        }, 1000);
                    }
                    else {
                        this.timerEnabled = false
                        this.$emit('input', null)
                        this.$emit('timerExpired', true)
                        if (this.$refs.otpInput) {
                            this.$refs.otpInput.clearInput()
                        }
                        clearInterval(this.$options.interval);
                    }
                }
            }
        },
        created() {
            this.timer = this.timerFrom;
        },
        methods: {
            otpHandleOnComplete(event) {
                this.$emit('input', event)
            },
            otpHandleOnChange(event) {
                this.$emit('input', event)
            }
        }
    };
</script>
