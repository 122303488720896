<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="m-3 ">
                <b-col>
                    <b-list-group>
                        <b-list-group-item class="d-flex justify-content-start align-items-center" v-for="(menu,j) in data" :key="j">
                            <span class="mr-5 font-weight-bold">{{ menu.key }}:</span>
                            <span v-if="menu.value2!=null">{{ menu.value }} / {{menu.value2}}</span>
                            <span v-else>{{ menu.value }} </span>
                        </b-list-group-item>

                    </b-list-group>
                </b-col>
                <div class="col-12 d-flex justify-content-between align-items-center mt-3">
                    <ValidationProvider name="status" rules="required"
                                        v-slot="{valid, errors}">
                        <div class="mr-3">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.status"
                                name="checkbox-1"
                                value="accepted"
                                unchecked-value="not_accepted"
                            >
                                I confirm that the information given in this form is true, complete and accurate
                            </b-form-checkbox>
                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <div class="d-flex">
                        <!--                        <b-button type="submit" @click="back" variant="success" class="mr-3">{{-->
                        <!--                                $t('back')-->
                        <!--                            }}-->
                        <!--                        </b-button>-->
                        <b-button @click="next" variant="primary" class="mr-3">{{
                                $t('next')
                            }}
                        </b-button>

                    </div>

                </div>
            </b-row>
        </ValidationObserver>


    </div>
</template>
<script>
import {mapGetters} from "vuex";
import moment from 'moment';


export default {
    data() {
        return {
            form: {},
            data: []
        }
    },

    created() {
        this.getData()
    },
    computed: {
        ...mapGetters({
            formData: "enrollmentAgreementForm/formData"
        })
    },
    methods: {
        async getData() {
            this.changeYear(this.formData)
        },
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
        async changeYear(data) {
            this.data.push(
                {
                    key: 'Date of Admission',
                    value:moment(data.registration_date).format('MM/DD/YYYY'),
                },
                {
                    key: 'Program Of Study',
                    value: data.program_name,
                },

                {
                    key: 'Program Start Date',
                    value: data.registration_semester_name,
                },

                {
                    key: 'Anticipated End Date',
                    value: data.anticipated_graduation_semester_name ? data.anticipated_graduation_semester_name : null,
                },
                {
                    key: 'Enrollment Status',
                    value: data.registration_type_text,
                },
                {
                    key: 'Total Credit Required For Graduation',
                    value: data.total_credit_required_for_graduation ? data.total_credit_required_for_graduation : null,
                    value2:data.credit?data.credit:null


                }
            )

            await this.$store.dispatch('enrollmentAgreementForm/deleteFormData');
            await this.$store.dispatch('enrollmentAgreementForm/saveFormData',data);

        },

    back() {
        this.$emit('back', -1);
    }
}
}
</script>
